










































import { createEmptyUser } from '@/entities/mappers/Factory'
import { User } from '@/entities/user/User'
import { Vue, Component, Prop } from 'vue-property-decorator'
import JudgeLockedBadge from '@/components/judge/JudgeLockedBadge.vue'
import UserRoleBadge from './UserRoleBadge.vue'

@Component({
    components: {
        JudgeLockedBadge,
        UserRoleBadge,
    },
})
export default class UserProperties extends Vue {
    @Prop() model?: User
    @Prop() showId?: boolean
    @Prop() showName?: boolean

    get user(): User {
        return this.model || createEmptyUser()
    }

    get expirationDate() {
        return this.user.judge?.expirationDate.format('DD.MM.YYYY HH:mm')
    }
}
