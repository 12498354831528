









































import { Event } from '@/entities/event/Event'
import { clone } from '@/entities/mappers/Factory'
import { trsl } from '@/i18n/lang'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class EventSelectionList extends Vue {
    @Prop() value!: number[]
    @Prop() events!: Event[]
    @Prop() height?: string
    @Prop() showSelectionButtons?: boolean

    toggleSelection(id: number) {
        const newValue = clone(this.value)
        const index = newValue.findIndex(value => value == id)
        if (index == -1) newValue.push(id)
        else newValue.splice(index, 1)
        this.$emit('input', newValue)
    }

    isSelected(id: number) {
        return this.value.includes(id)
    }

    // Table API
    rowClicked(item: { id: number }) {
        this.toggleSelection(item.id)
    }

    rowClass(item: { id: number }) {
        return 'clickable' + (this.isSelected(item.id) ? ' table-row-selected' : '')
    }

    fields = [
        {
            key: 'id',
            label: trsl('input.property.id'),
            sortable: true,
        },
        {
            key: 'name',
            label: trsl('input.property.name'),
            sortable: true,
        },
        {
            key: 'selected',
            label: '',
        },
    ]

    get displayEvents() {
        return this.events.map(event => {
            return {
                id: event.id,
                name: event.name,
            }
        })
    }

    selectAll() {
        this.$emit(
            'input',
            this.events.map(event => event.id)
        )
    }

    deselectAll() {
        this.$emit('input', [])
    }
}
