



































import { Event } from '@/entities/event/Event'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import EventSelectionList from './EventSelectionList.vue'

@Component({
    components: {
        EventSelectionList,
    },
})
export default class EventSelectionModal extends Vue {
    @Prop() value!: boolean
    @Prop() events!: Event[]
    @Prop() selectedIds!: number[]

    $refs!: {
        eventSelectionList: EventSelectionList
    }

    editSelection: number[] = []

    @Watch('show')
    overrideSelection() {
        if (this.show) {
            this.editSelection = this.selectedIds
        }
    }

    get show() {
        return this.value
    }

    set show(newValue: boolean) {
        this.$emit('input', newValue)
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    preventHide(event: any) {
        if (event.trigger == 'backdrop') event.preventDefault()
    }

    submit() {
        this.$emit('submit', this.editSelection)
    }

    selectAll() {
        this.$refs.eventSelectionList.selectAll()
    }

    deselectAll() {
        this.$refs.eventSelectionList.deselectAll()
    }
}
