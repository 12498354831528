















































import { Route } from '@/router'
import { Vue, Component } from 'vue-property-decorator'
import UserEditorModal from '@/components/user/UserEditorModal.vue'
import UserProperties from '@/components/user/UserProperties.vue'
import store from '@/store'
import EventList from '@/components/event/EventList.vue'
import EventSelectionModal from '@/components/event/EventSelectionModal.vue'
import { Event } from '@/entities/event/Event'
import { createEmptyUser } from '@/entities/mappers/Factory'

@Component({
    components: {
        UserEditorModal,
        UserProperties,
        EventList,
        EventSelectionModal,
    },
})
export default class UserDetailsView extends Vue {
    Route = Route

    mounted() {
        store.dispatch.user.fetchUsers()
        store.dispatch.event.fetchEvents()
    }

    get userId() {
        return parseInt(this.$route.params['userId'])
    }

    get user() {
        return store.getters.user.userWithId(this.userId) || createEmptyUser()
    }

    get assignedEvents() {
        return (
            this.user?.events
                .map(event => store.getters.event.eventWithId(event.id))
                .filter((event): event is Event => event != null) || []
        )
    }

    get assignedEventIds() {
        return this.user?.events.map(event => event.id) || []
    }

    get allEvents() {
        return store.getters.event.events
    }

    remove(eventId: number) {
        store.dispatch.message.showDialog({ textKey: 'remove-event', confirmKey: 'unassign' }).then(confirmed => {
            if (confirmed) {
                store.dispatch.event.removeUser({ eventId: eventId, userId: this.userId }).then(() => {
                    store.commit.message.showToast({ textKey: 'success.manage-user-events', variant: 'success' })
                })
            }
        })
    }

    submitEventSelection(ids: number[]) {
        store.dispatch.user.manageEvents({ userId: this.userId, newEventIds: ids }).then(() => {
            store.commit.message.showToast({ textKey: 'success.manage-user-events', variant: 'success' })
        })
    }

    showEditorModal = false
    edit() {
        this.showEditorModal = true
    }

    showEventSelectionModal = false
    manageEvents() {
        this.showEventSelectionModal = true
    }

    deletedUser() {
        this.$router.push({ name: Route.USERS })
    }
}
